import fetch from 'node-fetch'
import MagnoliaConfig from '../magnolia.config'

// Campaign manager
import ManagedCampaign from '../components/ManagedCampaign'
import ManagedCampaignPage from '../templates/pages/ManagedCampaignPage/ManagedCampaignPage'
import {
    getLocaleByReference,
    getParamsFromPath,
    getSiteByReference
} from '@salesforce/retail-react-app/app/utils/site-utils'

const campaignManager = {
    'campaign-manager:components/managed-campaign': ManagedCampaign,
    'dentsu:pages/ManagedCampaignPage/ManagedCampaignPage': ManagedCampaignPage
}
const mapping = {...MagnoliaConfig.componentMappings, ...campaignManager}
export const config = {...MagnoliaConfig, ...{componentMappings: mapping}}

export const nodeName = '/dentsu'

const baseUrl = 'https://sandbox-dentsu.author.ap-playground.magnolia-platform.com'
// const baseUrl ='http://localhost:8080'
const pagesApi = '/.rest/delivery/pages/v1'
const inspirationsApi = '/.rest/delivery/inspirations'
const inspirationsListApi = '/.rest/delivery/inspirations-list'
const templateAnnotationsApi = '/.rest/template-annotations/v1'
const storiesApi = '/.rest/delivery/stories'

function getValuesByPropertyName(obj, propertyName) {
    if (!obj || typeof obj !== 'object') {
        return []
    }

    return Object.entries(obj).reduce((values, [key, value]) => {
        if (key === propertyName) {
            return values.concat(value)
        } else if (typeof value === 'object') {
            return values.concat(getValuesByPropertyName(value, propertyName))
        } else {
            return values
        }
    }, [])
}

function updateObjectValue(obj, propertyName, oldValue, newValue) {
    if (obj && typeof obj === 'object') {
        for (const [key, value] of Object.entries(obj)) {
            if (key === propertyName && value === oldValue) {
                obj[key] = newValue || oldValue
            } else if (typeof value === 'object') {
                updateObjectValue(value, propertyName, oldValue, newValue)
            }
        }
    }
}

async function referenceResolver(page, propertyName, resolver, args) {
    const parsedPage = JSON.parse(page)
    const valuesByPropertyName = getValuesByPropertyName(parsedPage, propertyName)

    if (valuesByPropertyName.length > 0) {
        const values = await resolver(valuesByPropertyName, args)

        for (const i in valuesByPropertyName) {
            updateObjectValue(parsedPage, propertyName, valuesByPropertyName[i], values[i]) ||
            parsedPage
        }
    }
    return JSON.stringify(parsedPage)
}

function hasJsonStructure(str) {
    if (typeof str !== 'string') return false
    try {
        const result = JSON.parse(str)
        const type = Object.prototype.toString.call(result)
        return type === '[object Object]' || type === '[object Array]'
    } catch (err) {
        return false
    }
}

async function resolveProductId(values, args) {
    const productIdsArray = []
    let productId

    for (let value of values) {
        if (hasJsonStructure(value)) {
            const parsedValue = JSON.parse(value)
            productId = parsedValue.productId
        } else {
            productId = value
        }
        productIdsArray.push(productId?.split('~')[1])
    }
    return Promise.all(
        productIdsArray.map((id) => {
            return id
                ? args.api.shopperProducts.getProduct({
                    parameters: {
                        id: id,
                        allImages: false
                    }
                })
                : {}
        })
    )
}

function resolveCategoryId(values) {
    let id
    return values.map((categoryId) => {
        if (hasJsonStructure(categoryId)) {
            const parsedValue = JSON.parse(categoryId)
            id = parsedValue.categoryId
        } else {
            id = categoryId
        }
        return {id: id.split('~')[1]}
    })
}

export async function getMagnoliaEditablePage(args) {
    let templateAnnotationsPath = ''
    let templateAnnotationsWorkspace = '/website'
    let api = pagesApi
    const currentLanguage = getCurrentLanguage(args.location.pathname)
    const pagePath = removeCurrentLanguage(args.location.pathname, currentLanguage)

    const url = pagePath + args.location.search
    const urlObject = new URL(url, baseUrl)
    urlObject.searchParams.set('lang', currentLanguage)

    let pathName = args.location.pathname === '/' ? nodeName : pagePath

    const mgnlPreview = urlObject.searchParams.get('mgnlPreview')
    if (mgnlPreview === 'false') urlObject.searchParams.set('variants', 'all')

    if (pathName.includes('/campaign/')) {
        api = '/.rest/campaign-manager'
        pathName = pathName.replace('/campaign/', '/')
        templateAnnotationsWorkspace = '/campaign-manager'
    }

    let fullPath = baseUrl + api + pathName + urlObject.search
    const pagesRes = await fetch(fullPath)

    let page = await pagesRes.text()

    // Change assets links to full url
    page = page.replace(/\/dam\//g, baseUrl + '/dam/')

    // Resolve productId
    // page = await referenceResolver(page, 'productId', resolveProductId, args)

    // Resolve categoryId
    page = await referenceResolver(page, 'categoryId', resolveCategoryId, args)

    page = JSON.parse(page)

    // Send template annotations path only inside Magnolia WYSIWYG
    if (url.includes('mgnlPreview')) {
        templateAnnotationsPath =
            baseUrl + templateAnnotationsApi + templateAnnotationsWorkspace + pathName
    }
    return {
        page,
        templateAnnotationsPath
    }
}

export async function getPageDetails(pagePath) {
    const language = getCurrentLanguage(window.location.pathname)
    let fullPath = baseUrl + pagesApi + pagePath + '?lang=' + language
    const pagesRes = await fetch(fullPath)
    let page = await pagesRes.text()
    // Change assets links to full url
    page = page.replace(/\/dam\//g, baseUrl + '/dam/')
    page = JSON.parse(page)

    function intToHexColor(colorInt) {
        let red = (colorInt >> 16) & 0xFF
        let green = (colorInt >> 8) & 0xFF
        let blue = colorInt & 0xFF
        return `#${red.toString(16).padStart(2, '0')}${green.toString(16).padStart(2, '0')}${blue.toString(16).padStart(2, '0')}`.toLowerCase()
    }

    if (page?.textColor) {
        //convert double to hex
        page.textColor = intToHexColor(page.textColor)

    }
    if (page?.bannerColor) {
        //convert double to hex
        page.bannerColor = intToHexColor(page.bannerColor)
    }
    return page
}

export function getTimeNow() {
    const today = new Date()
    const yyyy = today.getFullYear()
    const mm = String(today.getMonth() + 1).padStart(2, '0') // Months are zero-based
    const dd = String(today.getDate()).padStart(2, '0')

    return `${yyyy}-${mm}-${dd}`
}


export async function getStories(args) {
    let props = {}
    //param use for get detail or paging
    let {id, slug, limit, offset, orderBy, isTitleRequired, additionalParam} = args.match.params
    let url = storiesApi
    let requiredTitleParam = ''
    if (
        isTitleRequired
    ) {
        if (getCurrentLanguage(window.location.pathname) === config.fallbackLocale) {
            requiredTitleParam = `&${encodeURIComponent(`title[null]`)}=false`
        } else {
            requiredTitleParam = `&${encodeURIComponent(`title_${getCurrentLanguage(window.location.pathname)}[null]`)}=false`
        }

    }

// Combine all query parameters dynamically
    const queryParams = {
        '@jcr:uuid': id,
        slug,
        limit,
        offset,
        orderBy,
        lang: getCurrentLanguage(window.location.pathname), // Add language dynamically
        ...(additionalParam && Object.fromEntries(
            Object.entries(additionalParam).map(([key, value]) => [key, value])
        )) // Dynamically add additional parameters if present
    }

// Build the query string
    const queryString = Object.entries(queryParams)
        .filter(([_, value]) => value !== undefined) // Exclude undefined values
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`) // Encode keys and values
        .join('&') // Join with `&`

// Append the query string to the base URL
    if (queryString) {
        url += `?${queryString}` + requiredTitleParam
    }

// Fetch the data
    const res = await fetch(baseUrl + url)
    let text = await res.text()
    // Change assets links to full url
    text = text.replace(/\/dam\//g, baseUrl + '/dam/')
    const jsonObj = JSON.parse(text)
    if (id || slug) {
        props.story = jsonObj.results[0]
    } else {
        props.total = jsonObj.total
        props.offset = jsonObj.offset
        props.limit = jsonObj.limit
        props.stories = jsonObj.results
    }
    return props
}

export async function getInspirations(args) {
    const {id} = args.match.params
    let props = {}
    let url = inspirationsListApi

    if (id) {
        url = inspirationsApi + '/' + id
    }

    const res = await fetch(baseUrl + url)
    let text = await res.text()

    // Change assets links to full url
    text = text.replace(/\/dam\//g, baseUrl + '/dam/')

    if (id) {
        // Resolve productId
        // text = await referenceResolver(text, 'productId', resolveProductId, args)

        props.inspiration = JSON.parse(text)
    } else {
        props.inspirations = JSON.parse(text).results
    }

    return props
}

export async function get(url, data) {
    let fullUrl = url

    const response = await fetch(baseUrl + fullUrl, data)
    const json = await response.json()

    if (json.error) return false

    return json
}

export async function getNavigation() {
    let fullPath = '/.rest/navigations?lang=' + getCurrentLanguage(window.location.pathname)
    const navigations = await get(fullPath)

    let pathname = window.location.pathname
    let path = pathname.split('/', 2).join('/')
    let nav = navigations.results.filter((item) => item['@path'] === path)
    if (nav.length === 0) {
        nav = navigations.results.filter((item) => item['@path'] === nodeName)
    }

    return nav.length > 0 ? nav[0] : {}
}

export function getPageLink(url = '') {
    const urlObject = new URL(url, baseUrl)
    return urlObject.pathname.replace(new RegExp('.html', 'g'), '')
}

export async function getEnhancedCategory(categoryId) {
    const enhancementInfo = await get(
        '/.rest/ecommerce-categories?externalId%5Blike%5D=%25~' + categoryId + '&lang=' + getCurrentLanguage(window.location.pathname)
    )
    return enhancementInfo
}

export function getImageUrl(image) {
    let imageLink = '#'
    if (!image) return imageLink
    if (image['@link']) {
        if (image['@link'].indexOf('http') > -1) {
            return image['@link']
        } else {
            imageLink = baseUrl + image['@link']
        }
    } else {
        imageLink = baseUrl + '/dam/' + image
    }

    return imageLink
}

export function getBaseUrl() {
    return baseUrl
}

export const exportedForTestingGetValuesByPropertyName = (obj, propertyName) =>
    getValuesByPropertyName(obj, propertyName)

export const exportedForTestingUpdateObjectValue = (obj, propertyName, oldValue, newValue) =>
    updateObjectValue(obj, propertyName, oldValue, newValue)

export const exportedForTestingResolveCategoryId = (values) => resolveCategoryId(values)

export function getLanguages() {
    return config.locales
}

export function getCurrentLanguage(path) {
    const languages = getLanguages()
    for (const language of languages) {
        if (new RegExp('/' + language + '($|/)').test(path)) {
            return language
        }
    }

    return config.fallbackLocale
}

export function removeCurrentLanguage(path, currentLanguage) {
    return path.replace(new RegExp('/' + currentLanguage + '($|/)'), '/')
}

export const getPathWithLocaleCustom = (shortCode, buildUrl, opts = {}) => {
    const location = opts.location ? opts.location : window.location
    let {siteRef, localeRef} = getParamsFromPath(`${location.pathname}${location.search}`)
    let {pathname, search} = location

    //get current locale from pathName
    const currentLocale = pathname.split('/')[1]
    if (config.locales.includes(currentLocale)) {
        localeRef = currentLocale
    }
    // sanitize the site from current url if existing
    if (siteRef) {
        pathname = pathname.replace(`/${siteRef}`, '')
        search = search.replace(`site=${siteRef}`, '')
    }
    // sanitize the locale from current url if existing
    if (localeRef) {
        pathname = pathname.replace(`/${localeRef}`, '')
        search = search.replace(`locale=${localeRef}`, '')
    }
    // remove ending any &
    search = search.replace(/&$/, '')

    // Remove query parameters
    const {disallowParams = []} = opts

    let queryString = new URLSearchParams(`${search}`)

    if (disallowParams.length) {
        disallowParams.forEach((param) => {
            queryString.delete(param)
        })
    }

    const site = getSiteByReference(siteRef)
    const locale = getLocaleByReference(site, shortCode)
    // rebuild the url with new locale,
    const newUrl = buildUrl(
        `${pathname}${Array.from(queryString).length !== 0 ? `?${queryString}` : ''}`,
        // By default, as for home page, when the values of site and locale belongs to the default site,
        // they will be not shown in the url just
        site.alias || site.id,
        locale?.alias || locale?.id
    )
    const localePath = locale?.id.replace('-', '_')
    //check localePath defferent from current localePath
    if (localePath !== config.fallbackLocale) {
        if (newUrl === '/') {
            return `/${localePath}${nodeName}`
        }
        return `/${localePath}${newUrl}`
    }
    return newUrl
}
