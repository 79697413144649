import React from 'react'
import PropTypes from 'prop-types'
import {chakra, Box, Divider, Heading} from '@chakra-ui/react'

const StyledBox = chakra(Box, {
    baseStyle: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill,minmax(200px, 1fr))'
    }
})

const StyledContainer = chakra(Box, {
    baseStyle: {
        paddingTop: '4rem',
        paddingBottom: '4rem'
    }
})

const StyledDivider = chakra(Divider, {
    baseStyle: {
        borderColor: 'gray.900',
        borderWidth: '1px'
    }
})

const StyledHeading = chakra(Heading, {
    baseStyle: {
        gridColumn: 'span 5',
        fontSize: ['3rem', '3rem', '5rem', '6rem'],
        lineHeight: ['3rem', '3rem', '5rem', '6rem'],
        textTransform: 'uppercase',
        marginBottom: '1rem'
    }
})

function Title(props) {
    const {title} = props

    return (
        <StyledContainer>
            <StyledBox>
                <StyledHeading as="h2">{title}</StyledHeading>
            </StyledBox>
            <StyledDivider />
        </StyledContainer>
    )
}

Title.propTypes = {
    title: PropTypes.string
}

export default Title
