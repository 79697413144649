import React from 'react'
import PropTypes from 'prop-types'
import {Box} from '@chakra-ui/react'
import {EditableArea} from '@magnolia/react-editor'
import Seo from '@salesforce/retail-react-app/app/components/seo'

const Campaign = (props) => {
    const {main, title, description, keywords} = props

    return (
        <Box data-testid="home-page" layerStyle="page">
            <Seo title={title} description={description}>
                <meta name="keywords" content={keywords} />
            </Seo>
            {main && <EditableArea content={main} />}
        </Box>
    )
}

Campaign.propTypes = {
    main: PropTypes.object,
    title: PropTypes.string,
    description: PropTypes.string,
    keywords: PropTypes.array
}

export default Campaign
