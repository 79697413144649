import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {
    chakra,
    keyframes,
    AspectRatio,
    Image,
    Img,
    Box,
    Skeleton as ChakraSkeleton,
    Stack
} from '@chakra-ui/react'
import {isServer} from '@salesforce/retail-react-app/app/utils/utils'
import {Link} from 'react-router-dom'
import {productUrlBuilder} from '@salesforce/retail-react-app/app/utils/url'
import {useProduct} from '@salesforce/commerce-sdk-react'

const StyledContainer = chakra('div', {
    baseStyle: {
        position: 'relative',
        padding: '2rem 0'
    }
})

const pulse = keyframes`
  from {transform: scale(1);}
  to {transform: scale(1.4)}
`

const StyledSpot = chakra('div', {
    baseStyle: {
        position: 'absolute',
        height: '28px',
        width: '28px',
        background: '#fff',
        borderRadius: '50%',
        cursor: 'pointer',
        boxShadow: '0 0 10px 1px #000',
        _before: {
            content: '""',
            borderRadius: '50%',
            width: '36px',
            height: '36px',
            top: '-7px',
            left: '-7px',
            border: '3px solid #FFF',
            position: 'absolute',
            boxShadow: '0 0 10px 1px #000, inset 0 0 5px 0px #000'
        },
        _hover: {
            opacity: '1',
            _before: {
                animation: `${pulse} 700ms ease-in-out infinite alternate`
            }
        }
    }
})

const InfoLink = chakra(Link, {
    baseStyle: {
        position: 'absolute',
        top: '3rem',
        left: '1rem',
        maxWidth: '300px',
        padding: '1.5rem',
        background: '#fff',
        color: 'gray.700',
        boxShadow: '2px 4px 8px #5C5C5C',
        zIndex: '1',
        _hover: {
            boxShadow: '4px 8px 16px #5C5C5C'
        }
    }
})

const InfoTitle = chakra('div', {
    baseStyle: {
        marginBottom: '0.5em',
        fontWeight: 'bold',
        color: 'gray.900'
    }
})

const StyledBox = chakra(Box, {
    baseStyle: {
        position: 'absolute',
        top: '3rem',
        left: '1rem',
        maxWidth: '300px',
        padding: '1.5rem',
        background: '#fff',
        boxShadow: '2px 4px 8px #5C5C5C',
        zIndex: '1'
    }
})

const Skeleton = () => {
    return (
        <StyledBox data-testid="sf-product-tile-skeleton">
            <Stack spacing={2}>
                <ChakraSkeleton width="80px" height="20px" />
                <ChakraSkeleton width={{base: '120px', md: '220px'}} height="12px" />
                <ChakraSkeleton width={{base: '120px', md: '220px'}} height="12px" />
                <ChakraSkeleton width={{base: '120px', md: '220px'}} height="12px" />
                <Box>
                    <AspectRatio ratio={1}>
                        <ChakraSkeleton />
                    </AspectRatio>
                </Box>
            </Stack>
        </StyledBox>
    )
}

function HotspotImage({image}) {
    const src = image?.['@link']
    const alt = image?.metadata?.caption || image?.['@name']
    const hotspots = image?.focal?.hotspots
    const [selectedProductId, setSelectedProductId] = useState(null)
    const {
        data: product,
        isLoading,
        error
    } = useProduct({
        parameters: {
            id: selectedProductId,
            limit: 10
        }
    })
    let productImage = product?.imageGroups?.[0]?.images?.[0] || {}

    const handleSpotClick = (productId) => {
        const productReference = getProductId(productId)
        setSelectedProductId(productReference || undefined)
    }
    const getProductId = (product) => {
        const productId = JSON.parse(product || '{}').productId
        return productId ? productId.split('~')[1] : null
    }

    return (
        <StyledContainer>
            <AspectRatio ratio={4 / 3} width="full">
                {isServer ? (
                    <Image
                        alt={alt}
                        src={src}
                        onClick={() => setSelectedProductId(null)}
                        ignoreFallback={true}
                    />
                ) : (
                    <Img alt={alt} src={src} onClick={() => setSelectedProductId(null)} />
                )}
            </AspectRatio>
            {selectedProductId !== null &&
                (product?.id ? (
                    <InfoLink to={productUrlBuilder({id: product.id})}>
                        <InfoTitle>{product.name}</InfoTitle>
                        {product.shortDescription}
                        <AspectRatio ratio={1}>
                            <Image alt={productImage.alt} src={productImage.link} />
                        </AspectRatio>
                    </InfoLink>
                ) : (
                    <Skeleton />
                ))}
            {hotspots &&
                hotspots.map((spot) => {
                    return (
                        <StyledSpot
                            key={spot.left}
                            style={{
                                top: `calc(${spot.top}% - 20px)`,
                                left: `calc(${spot.left}% - 20px)`
                            }}
                            onClick={() => {
                                handleSpotClick(spot.productId)
                            }}
                            opacity={getProductId(spot.productId) === product?.id ? '1' : '0.5'}
                        />
                    )
                })}
        </StyledContainer>
    )
}

HotspotImage.propTypes = {
    image: PropTypes.object
}

export default HotspotImage
