import React from 'react'
import PropTypes from 'prop-types'
import {EditableArea} from '@magnolia/react-editor'

function ManagedCampaign(props) {
    const {main} = props

    return <div className="ManagedCampaign">{main && <EditableArea content={main} />}</div>
}

ManagedCampaign.propTypes = {
    main: PropTypes.object
}

export default ManagedCampaign
