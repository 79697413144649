import React from 'react'
import PropTypes from 'prop-types'
import ProductTile, {Skeleton as ProductTileSkeleton} from '../product-tile'
import {chakra, Box} from '@chakra-ui/react'
import {useProduct} from '@salesforce/commerce-sdk-react'
import {useServerContext} from '@salesforce/pwa-kit-react-sdk/ssr/universal/hooks'

const StyledBox = chakra(Box, {
    baseStyle: {
        display: 'inline-block',
        width: 'calc(1/3 * 100%)',
        padding: '10px',
        margin: '1rem 0'
    }
})

function Product(props) {
    let productSearchResult = {}
    const {res} = useServerContext()
    if (res) {
        res.set('Cache-Control', `max-age=10000`)
    }

    let {productId = {}} = props
    if (typeof productId === 'string') {
        productId = JSON.parse(productId)
    }
    let productReference = productId.productId ? productId.productId.split('~')[1] : null
    const {data: searchResult, isLoading} = useProduct({
        parameters: {
            id: productReference,
            limit: 10
        }
    })

    if (!isLoading) {
        productSearchResult = searchResult
    }

    const {id, currency, imageGroups, price, name} = productSearchResult || {}
    const product = {
        productId: id,
        currency,
        image: imageGroups?.[0]?.images?.[0] || {},
        price,
        productName: name
    }

    return id ? (
        <StyledBox>
            <ProductTile product={product} />
        </StyledBox>
    ) : (
        <StyledBox>
            <ProductTileSkeleton key={productId.productId} />
        </StyledBox>
    )
}

Product.propTypes = {
    productId: PropTypes.string
}

export default Product
