import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import {chakra, AspectRatio, Button, Box, Heading, Image, Img, Text, VStack} from '@chakra-ui/react'
import {ArrowForwardIcon} from '@chakra-ui/icons'
import {isServer} from '@salesforce/retail-react-app/app/utils/utils'
import {categoryUrlBuilder} from '@salesforce/retail-react-app/app/utils/url'

const StyledIcon = chakra(ArrowForwardIcon, {
    baseStyle: {
        background: 'blue.500',
        borderRadius: '50%',
        color: 'white',
        width: '2rem',
        height: '2rem',
        padding: '2px',
        marginLeft: '0.5rem'
    }
})

const RightLayoutHero = (props) => {
    const StyledContainer = chakra(Box, {
        baseStyle: {
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(20rem, 1fr))',
            padding: '3rem 0'
        }
    })

    const StyledBox = chakra(Box, {
        baseStyle: {
            zIndex: 2,
            marginTop: ['1rem', '1rem', '40%']
        }
    })

    const StyledButton = chakra(Button, {
        baseStyle: {
            background: 'transparent',
            textTransform: 'uppercase',
            paddingLeft: [0, '8.5rem'],
            fontSize: '1.5rem',
            fontWeight: '500'
        }
    })

    const StyledHeading = chakra(Heading, {
        baseStyle: {
            textTransform: 'uppercase',
            fontSize: ['3rem', '4rem', '7rem', '10rem'],
            lineHeight: ['3rem', '4rem', '9rem'],
            textIndent: [0, '-8rem'],
            paddingLeft: [0, '8rem'],
            wordSpacing: '100rem'
        }
    })

    const StyledText = chakra(Text, {
        baseStyle: {
            paddingLeft: [0, '8.5rem'],
            textTransform: 'uppercase',
            fontFamily: 'Inter',
            fontWeight: '500'
        }
    })

    const StyledVStack = chakra(VStack, {
        baseStyle: {
            marginRight: [0, 0, '-100%', '-100%'],
            alignItems: 'baseline',
            marginBottom: ['1rem', '2rem', '10rem']
        }
    })

    const {title, label, image, layout, categoryId, link} = props
    const smallImage = layout.smallImage || null
    const src = image?.['@link']
    const alt = image?.metadata?.caption || image?.['@name']
    const srcSmallImage = smallImage?.['@link']
    const altSmallImage = smallImage?.metadata?.caption || smallImage?.['@name']

    return (
        <StyledContainer position={{lg: 'relative'}}>
            <StyledBox>
                <StyledVStack>
                    <StyledText fontSize={{base: 'sm', lg: 'md'}}>{label}</StyledText>
                    <StyledHeading as="h2">{title}</StyledHeading>
                    {link && (
                        <Box paddingTop="2" width={{base: 'full', lg: 'inherit'}}>
                            <StyledButton as={Link} to={link} width={{base: 'full', md: 'inherit'}}>
                                {title}
                                <StyledIcon />
                            </StyledButton>
                        </Box>
                    )}
                    {categoryId && (
                        <Box paddingTop="2" width={{base: 'full', lg: 'inherit'}}>
                            <StyledButton
                                as={Link}
                                to={categoryUrlBuilder(categoryId)}
                                width={{base: 'full', md: 'inherit'}}
                            >
                                LOOK MORE
                                <StyledIcon />
                            </StyledButton>
                        </Box>
                    )}
                </StyledVStack>
                {srcSmallImage && (
                    <div>
                        {/* Server Image */}
                        <AspectRatio
                            ratio={1}
                            width="full"
                            maxWidth={{lg: 'sm'}}
                            display={isServer ? 'block' : 'none'}
                        >
                            <Img alt={altSmallImage} src={srcSmallImage} />
                        </AspectRatio>
                        {/* Client Image */}
                        <AspectRatio
                            ratio={1}
                            width="full"
                            maxWidth={{lg: 'sm'}}
                            display={isServer ? 'none' : 'block'}
                        >
                            <Image alt={altSmallImage} src={srcSmallImage} ignoreFallback={true} />
                        </AspectRatio>
                    </div>
                )}
            </StyledBox>
            <div>
                {/* Server Image */}
                <AspectRatio
                    ratio={7 / 10}
                    width="full"
                    maxWidth={{lg: '3xl'}}
                    display={isServer ? 'block' : 'none'}
                >
                    <Img alt={alt} src={src} />
                </AspectRatio>
                {/* Client Image */}
                <AspectRatio
                    ratio={7 / 10}
                    width="full"
                    maxWidth={{lg: '3xl'}}
                    display={isServer ? 'none' : 'block'}
                >
                    <Image alt={alt} src={src} ignoreFallback={true} />
                </AspectRatio>
            </div>
        </StyledContainer>
    )
}
RightLayoutHero.propTypes = {
    title: PropTypes.string,
    label: PropTypes.string,
    image: PropTypes.object,
    layout: PropTypes.object,
    categoryId: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    link: PropTypes.string
}

const LeftLayoutHero = (props) => {
    const StyledContainer = chakra(Box, {
        baseStyle: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            padding: '2rem 0'
        }
    })

    const StyledBox = chakra(Box, {
        baseStyle: {
            textAlign: 'left',
            marginLeft: '3rem',
            marginTop: '40%'
        }
    })

    const StyledButton = chakra(Button, {
        baseStyle: {
            background: 'transparent',
            textTransform: 'uppercase',
            fontSize: '1.5rem',
            fontWeight: '500'
        }
    })

    const StyledHeading = chakra(Heading, {
        baseStyle: {
            textTransform: 'uppercase',
            fontSize: ['3rem', '4rem', '5rem', '10rem'],
            lineHeight: ['3rem', '4rem', '5rem', '9rem']
        }
    })

    const StyledText = chakra(Text, {
        baseStyle: {
            textTransform: 'uppercase',
            fontFamily: 'Inter',
            fontWeight: '500'
        }
    })

    const {title, label, image, categoryId, link} = props
    const src = image?.['@link']
    const alt = image?.metadata?.caption || image?.['@name']
    let categoryName = categoryId && categoryId.id
    if (categoryId) {
        categoryName = categoryName && categoryName.replace(/-/g, ' ')
    }

    return (
        <StyledContainer>
            <>
                {/* Server Image */}
                <AspectRatio
                    ratio={4 / 5}
                    width="full"
                    maxWidth={{lg: '2xl'}}
                    display={isServer ? 'block' : 'none'}
                >
                    <Img alt={alt} src={src} />
                </AspectRatio>
                {/* Client Image */}
                <AspectRatio
                    ratio={4 / 5}
                    width="full"
                    maxWidth={{lg: '2xl'}}
                    display={isServer ? 'none' : 'block'}
                >
                    <Image alt={alt} src={src} ignoreFallback={true} />
                </AspectRatio>
            </>
            <StyledBox>
                <StyledText fontSize={{base: 'sm', lg: 'md'}}>{label}</StyledText>
                <StyledHeading as="h2">{title}</StyledHeading>
                {link && (
                    <Box paddingTop="2" width={{base: 'full', lg: 'inherit'}}>
                        <StyledButton as={Link} to={link} width={{base: 'full', md: 'inherit'}}>
                            LOOK MORE
                            <StyledIcon />
                        </StyledButton>
                    </Box>
                )}
                {categoryId && categoryName && (
                    <Box paddingTop="2" width={{base: 'full', lg: 'inherit'}}>
                        <StyledButton
                            as={Link}
                            to={categoryUrlBuilder(categoryId)}
                            width={{base: 'full', md: 'inherit'}}
                        >
                            {categoryName}
                            <StyledIcon />
                        </StyledButton>
                    </Box>
                )}
            </StyledBox>
        </StyledContainer>
    )
}

LeftLayoutHero.propTypes = {
    title: PropTypes.string,
    label: PropTypes.string,
    image: PropTypes.object,
    layout: PropTypes.object,
    categoryId: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    link: PropTypes.string
}

const Hero = (props) => {
    const {layout} = props
    const rightLayout = !!(layout.field === 'right')
    return <div>{rightLayout ? <RightLayoutHero {...props} /> : <LeftLayoutHero {...props} />}</div>
}

Hero.displayName = 'Hero'

Hero.propTypes = {
    /**
     * Promotion label
     */
    label: PropTypes.string,
    /**
     * Hero component main title
     */
    title: PropTypes.string,
    layout: PropTypes.object
}

export default Hero
