import React, {useState, useEffect, createContext, useContext} from 'react'
import {getStories, getTimeNow} from '../../utils/magnoliaUtils'
import {Heading, FormLabel, Box, Link, chakra, Text} from '@chakra-ui/react'
import {Spinner} from '@salesforce/retail-react-app/app/components/shared/ui'
import {useIntl} from 'react-intl'
import useMultiSite from '@salesforce/retail-react-app/app/hooks/use-multi-site'
const StyledArrowIcon = chakra('svg', {
    baseStyle: {
        marginLeft: '5px',
        width: '24px',
        height: '24px',
        fill: '#FFFFFF'
    }
})
const StyledLink = chakra(Link, {
    baseStyle: {
        display: 'block',
        flex: '1',
        flexBasis: ['100%', '0%', '0%', '0%'],
        overflow: 'hidden',
        margin: ['4px 0', '0 auto', '0 auto', '0 auto'],
        marginLeft: ['0', '20px', '20px', '20px'],
        cursor: 'pointer',
        whiteSpace: ['normal', 'nowrap', 'nowrap', 'nowrap'],
        textOverflow: 'ellipsis',
        lineHeight: '34px',
        fontWeight: '100'
    }
})
const StyledText = chakra(Text, {
    baseStyle: {
        width: '90px',
        display: 'flex',
        alignItems: 'center',
        fontSize: ['14px', '16px', '16px', '16px'],
        fontWeight: '100'
    }
})
const StyledFormLabelAuthor = chakra(FormLabel, {
    baseStyle: {
        color: '#FFFFFF',
        background: '#333',
        textAlign: 'center',
        margin: 'auto 5px',
        padding: '5px',
        flex: '1',
        maxWidth: '200px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        borderRadius: '8px',
        fontSize: ['14px', '16px', '16px', '16px']

    }
})
const StyledBox = chakra(Box, {
    baseStyle: {
        margin: '15px 5px 15px 0',
        display: 'flex',
        flexWrap: 'wrap'
    }
})
const LinkViewAll = chakra('a', {
    baseStyle: {
        display: 'flex',
        padding: '5px 10px',
        margin: '5px 0',
        backgroundColor: '#333',
        color: '#FFFFFF',
        textDecoration: 'none',
        borderRadius: '8px',
        fontSize: '16px',
        textAlign: 'center',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
        _hover: {
            backgroundColor: '#6e6868'
        }
    }
})

const Stories = (props) => {
    const {mainTitle, subTitle, linkText, detailPageLink, listingPageLink, emptyDataMessage} = props
    const [stories, setStories] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState({})
    const intl = useIntl()
    const {formatDate} = intl
    const {site, buildUrl} = useMultiSite()
    const {l10n} = site
    const [locale, setLocale] = useState(intl.locale)
    const [languagePath, setLanguagePath] = useState('')
    useEffect(() => {
        if (locale!== l10n?.defaultLocale ) {
            setLanguagePath(`/${locale.replace("-","_")}`)
        }
    }, [locale])
    useEffect(() => {
        const getStoriesData = async () => {
            try {

                const storiesRes = await getStories({
                    match: {
                        params: {
                            limit: 5,
                            orderBy: 'created desc',
                            isTitleRequired: true,
                            additionalParam: {
                                'created[lte]': getTimeNow()
                            }
                        }
                    }
                })
                if (storiesRes?.total > 0) {
                    setStories(storiesRes?.stories)
                }
            } catch (err) {
                setStories([])
                setError(err)
            } finally {
                setLoading(false) // Set loading to false after fetching data
            }
        }
        getStoriesData()
    }, [])

    return (
        <Box>
            <StyledBox sx={{borderBottom: '1px solid #c9c9c9'}}>
                <Heading as="h2" size="md" sx={{margin: '10px', fontSize: '16px', marginLeft: '0'}}>
                    {props?.mainTitle}
                </Heading>
                <Box as="span" sx={{marginTop: '10px', fontSize: '14px'}}>
                    {props?.subTitle}
                </Box>
            </StyledBox>

            {loading ? (
                <Spinner size="xl" />
            ) : (
                <>
                    {stories && stories.length > 0 ? (
                        stories.map((story, i) => (
                            <StyledBox key={i}>
                                <StyledText>
                                    {story?.created
                                        ? locale === 'ja-JP'
                                            ? new Date(story.created)
                                                  .toISOString()
                                                  .split('T')[0]
                                                  .replace(/-/g, '/')
                                            : formatDate(new Date(story.created), {
                                                  year: 'numeric',
                                                  day: 'numeric',
                                                  month: 'short'
                                              })
                                        : ''}
                                </StyledText>

                                {story?.categories && (
                                    <StyledFormLabelAuthor>
                                        {story?.categories?.name || 'No category available'}
                                    </StyledFormLabelAuthor>
                                )}

                                <StyledLink
                                    href={`${languagePath}${props.detailPageLink}?article=${story?.slug}`}
                                >
                                    {story?.title || 'No Title'}
                                </StyledLink>
                            </StyledBox>
                        ))
                    ) : (
                        <StyledText>{emptyDataMessage}</StyledText>
                    )}
                    {stories && stories.length > 0 && (
                        <Box style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <LinkViewAll href={`${languagePath}${props?.listingPageLink}`}>
                                {props?.linkText}
                                <StyledArrowIcon
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 25 25"
                                >
                                    <path d="m17.5 5.999-.707.707 5.293 5.293H1v1h21.086l-5.294 5.295.707.707L24 12.499l-6.5-6.5z" />
                                </StyledArrowIcon>
                            </LinkViewAll>
                        </Box>
                    )}
                </>
            )}
        </Box>
    )
}
export default Stories