import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import {chakra, Button, Box, Heading, Text} from '@chakra-ui/react'
import {ArrowForwardIcon} from '@chakra-ui/icons'
import {categoryUrlBuilder} from '@salesforce/retail-react-app/app/utils/url'
import {getImageUrl, getPageLink} from '../../utils/magnoliaUtils'

const StyledBox = chakra(Box, {
    baseStyle: {
        textAlign: 'center',
        padding: '2rem',
        margin: '2rem 0',
        backgroundPosition: 'center',
        minHeight: '20vh'
    }
})

const StyledButton = chakra(Button, {
    baseStyle: {
        background: 'transparent',
        textTransform: 'uppercase',
        fontSize: '1.5rem',
        fontWeight: '500'
    }
})

const StyledIcon = chakra(ArrowForwardIcon, {
    baseStyle: {
        background: 'blue.500',
        borderRadius: '50%',
        color: 'white',
        width: '2rem',
        height: '2rem',
        padding: '2px',
        marginLeft: '0.5rem'
    }
})

const StyledHeading = chakra(Heading, {
    baseStyle: {
        textTransform: 'uppercase',
        fontSize: ['3rem', '4rem', '5rem', '10rem'],
        lineHeight: ['3rem', '4rem', '5rem', '9rem']
    }
})

const StyledText = chakra(Text, {
    baseStyle: {
        textTransform: 'uppercase',
        fontFamily: 'Inter',
        fontWeight: '500',
        paddingTop: 2
    }
})

const Banner = ({title, subtitle, textColor, categoryId, image, cta}) => {
    const src = image?.['@link']
    let imageUrl = src || getImageUrl(image)

    const style = {backgroundImage: `url(${imageUrl})`, color: textColor}

    let categoryName = categoryId && categoryId.id
    if (categoryId) {
        categoryName = categoryName && categoryName.replace(/-/g, ' ')
    }

    return (
        <div>
            <StyledBox style={style}>
                <StyledHeading as="h2">{title}</StyledHeading>
                <StyledText fontSize={{base: 'sm', lg: 'md'}}>{subtitle}</StyledText>
                {categoryId && categoryName && (
                    <Box paddingTop="2" width={{base: 'full', lg: 'inherit'}}>
                        <StyledButton
                            as={Link}
                            to={categoryUrlBuilder(categoryId)}
                            width={{base: 'full', md: 'inherit'}}
                        >
                            {categoryName}
                            <StyledIcon />
                        </StyledButton>
                    </Box>
                )}
                {cta && cta.link && (
                    <Box paddingTop="2" width={{base: 'full', lg: 'inherit'}}>
                        <StyledButton
                            as={Link}
                            to={getPageLink(cta.link)}
                            width={{base: 'full', md: 'inherit'}}
                        >
                            {cta.text}
                            <StyledIcon />
                        </StyledButton>
                    </Box>
                )}
            </StyledBox>
        </div>
    )
}

Banner.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    textColor: PropTypes.string,
    categoryId: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    cta: PropTypes.object
}

export default Banner
