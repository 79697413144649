import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import {chakra, Button, Box, Heading} from '@chakra-ui/react'
import {ArrowForwardIcon} from '@chakra-ui/icons'
import {categoryUrlBuilder} from '@salesforce/retail-react-app/app/utils/url'

const StyledContainer = chakra('div', {
    baseStyle: {
        position: 'relative',
        margin: '1rem 0',
        backgroundImage: 'linear-gradient(45deg, var(--chakra-colors-gray-500), transparent)',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    }
})

const StyledDiv = chakra('div', {
    baseStyle: {
        width: ['100%', '75%', '50%'],
        background: 'linear-gradient(90deg, var(--chakra-colors-gray-900) 80%, transparent 100%)',
        padding: '2rem 10% 2rem 1rem',
        color: 'var(--chakra-colors-gray-50)'
    }
})

const StyledHeading = chakra(Heading, {
    baseStyle: {
        textTransform: 'uppercase',
        fontSize: ['1.5rem', '2rem', '4rem', '6rem'],
        lineHeight: ['1rem', '6rem'],
        textShadow: '2px 2px 5px rgba(0,0,0,0.6)'
    }
})

const StyledText = chakra('div', {
    baseStyle: {
        fontFamily: 'Inter',
        fontWeight: '500',
        fontSize: ['0.8rem', '1.2rem', '1.2rem', '1.5rem'],
        padding: ['1rem 0', '2rem 0']
    }
})

const StyledButton = chakra(Button, {
    baseStyle: {
        background: 'transparent',
        textTransform: 'uppercase',
        fontSize: ['0.8rem', '1.5rem'],
        fontWeight: '500'
    }
})

const StyledIcon = chakra(ArrowForwardIcon, {
    baseStyle: {
        background: 'blue.500',
        borderRadius: '50%',
        color: 'white',
        width: ['1.5rem', '2rem'],
        height: ['1.5rem', '2rem'],
        padding: '2px',
        marginLeft: '0.5rem'
    }
})

const SpecialOffer = ({title, text, image, categoryId, link}) => {
    const src = image?.['@link']

    return (
        <div>
            {(title || text || image || categoryId || link) && (
                <StyledContainer style={src && {backgroundImage: 'url(' + src + ')'}}>
                    <StyledDiv>
                        <StyledHeading as="h2">{title}</StyledHeading>
                        <StyledText dangerouslySetInnerHTML={{__html: text}}></StyledText>
                        {link && (
                            <Box width={{base: 'full', lg: 'inherit'}}>
                                <StyledButton
                                    as={Link}
                                    to={link}
                                    width={{base: 'full', md: 'inherit'}}
                                >
                                    {title}
                                    <StyledIcon />
                                </StyledButton>
                            </Box>
                        )}
                        {categoryId && (
                            <Box width={{base: 'full', lg: 'inherit'}}>
                                <StyledButton
                                    as={Link}
                                    to={categoryUrlBuilder(categoryId)}
                                    width={{base: 'full', md: 'inherit'}}
                                >
                                    LOOK MORE
                                    <StyledIcon />
                                </StyledButton>
                            </Box>
                        )}
                    </StyledDiv>
                </StyledContainer>
            )}
        </div>
    )
}

SpecialOffer.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.object,
    categoryId: PropTypes.string,
    link: PropTypes.string
}

export default SpecialOffer
