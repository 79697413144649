import React from 'react'
import PropTypes from 'prop-types'
import RecommendedProducts from '@salesforce/retail-react-app/app/components/recommended-products'

const RecommendedProductsWrapper = ({title, recommender}) => {
    const items = recommender[recommender.field] || null
    let products = null

    if (items) {
        if (Array.isArray(items)) {
            products = items.map((product) => {
                const result = product && JSON.parse(product)
                const productId = result && result.productId.replace(/^.+~/, '')
                return productId
            })
        } else {
            const result = JSON.parse(items)
            const productId = result && result.productId.replace(/^.+~/, '')
            products = [productId]
        }
        products = products?.map((id) => ({id}))
    }

    return (
        <section>
            {recommender && (
                <RecommendedProducts
                    title={title}
                    recommender={recommender.field.replace(/_/g, '-')}
                    mx={{base: -4, md: -8, lg: 0}}
                    products={products}
                />
            )}
        </section>
    )
}

RecommendedProductsWrapper.propTypes = {
    title: PropTypes.string,
    recommender: PropTypes.object
}

export default RecommendedProductsWrapper
