import React, { useState, useEffect } from 'react';
import { Heading, FormLabel, Box, chakra, Button, HStack } from '@chakra-ui/react';
import Seo from "@salesforce/retail-react-app/app/components/seo";
import PropTypes from "prop-types";
import {getStories, getTimeNow} from '../../../utils/magnoliaUtils'
import Link from "@salesforce/retail-react-app/app/components/link";
import {Spinner} from "@salesforce/retail-react-app/app/components/shared/ui";
import {useIntl} from 'react-intl'
import useMultiSite from "@salesforce/retail-react-app/app/hooks/use-multi-site";

const StyleButton = chakra(Button, {
    baseStyle: {
        backgroundColor:'#333',
        background:'none !important',
        color:'#333',
        border:'1px solid',
        _hover: {
            background:'gray !important',
        },
        _disabled: {
            _hover: {
                background: 'none !important',
            },
        },
    },
});
const StyledFormLabel = chakra(FormLabel, {
    baseStyle: {
        width: '90px',
        display: 'flex',
        alignItems: 'center',
        fontSize:   ['14px', '16px', '16px', '16px'],
        fontWeight:'100'
    },
});
const StyledLink = chakra(Link, {
    baseStyle: {
        display: 'block',
        flex: '1',
        flexBasis: ['100%', '0%', '0%', '0%'],
        overflow: 'hidden',
        margin: ['4px 0', '0 auto', '0 auto', '0 auto'],
        marginLeft: ['0', '20px', '20px', '20px'],
        cursor: 'pointer',
        whiteSpace: ['normal', 'nowrap', 'nowrap', 'nowrap'],
        textOverflow: 'ellipsis',
        lineHeight: '34px',
        fontWeight: '100'
    }
})
const StyledFormLabelAuthor = chakra(FormLabel, {
    baseStyle:  {
        color: '#FFFFFF',
        background: '#333',
        textAlign: 'center',
        margin: 'auto 5px',
        padding: '5px',
        flex: '1',
        maxWidth: '200px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        borderRadius: '8px',
        fontSize:   ['14px', '16px', '16px', '16px'],
    },
});
const StyledBox = chakra(Box, {
    baseStyle: {
        margin: '15px 5px',
        display: 'flex',
        flexWrap: 'wrap',
        borderBottom: '1px solid #c9c9c9',
    },
});
const ContentBox = chakra(Box, {
    baseStyle: {
        margin: ['0','0 45px','0 45px','0 45px'],
    },
});
const StyleHeading = chakra(Heading, {
    baseStyle: {
        fontWeight:'100',
        fontSize:'14px',
    },
});


const StoriesListing = (props) => {
    const { main, title, description, keywords, detailLink,mainListTitle,subListTitle,emptyDataMessage,breadcrumbHomeLabel,breadcrumbHomeLink,breadcrumbArticleListLabel} = props;
    const [stories, setStories] = useState([]);
    const [startIndex, setStartIndex] = useState(0);
    const [totalStories, setTotalStories] = useState(0);
    const storiesPerPage = 10 ; // Number of stories per page
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState({});
    const intl = useIntl()
    const {formatDate} = intl
    const {site, buildUrl} = useMultiSite()
    const {l10n} = site
    const [locale, setLocale] = useState(intl.locale)
    const [languagePath, setLanguagePath] = useState('')

    useEffect(() => {
        if (locale!== l10n?.defaultLocale ) {
            setLanguagePath(`/${locale.replace("-","_")}`)
        }
    }, [locale])
    useEffect(() => {
        const getStoriesData = async () => {
            try {
                const objRes = await getStories({
                    match: {
                        params: {
                            limit: storiesPerPage,
                            offset: startIndex,
                            isTitleRequired: true,
                            orderBy: 'created desc',
                            additionalParam: {
                                'created[lte]': getTimeNow()
                            }
                        }
                    }
                });
                if (objRes?.total > 0  ){
                    setStories(objRes?.stories);
                    setTotalStories(objRes?.total );
                }

            } catch (err) {
                setStories([]);
                setError(err)
            } finally {
                setLoading(false); // Set loading to false after fetching data
            }
        };
        getStoriesData();
    },[startIndex] );

    // Calculate total pages
    const totalPages = Math.ceil(totalStories / storiesPerPage);

    // Handle previous page
    const handlePrevPage = () => {
        if (startIndex > 0) {
            setStartIndex(startIndex - storiesPerPage);
        }
    };

    // Handle next page
    const handleNextPage = () => {
        if (startIndex + storiesPerPage < totalStories) {
            setStartIndex(startIndex + storiesPerPage);
        }
    };
    return (
        <Box data-testid="home-page" layerStyle="page">
            <Seo title={title} description={description}>
                <meta name="keywords" content={keywords} />
            </Seo>

            <Box sx={{display: 'flex', alignItems: 'center', marginTop: '5px'}}>
                <StyleHeading as="a" href={`${languagePath}${breadcrumbHomeLink}`}>
                    {breadcrumbHomeLabel??"Home"}
                </StyleHeading>

                <svg
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16px"
                    height="16px"
                >
                    <g id="SVGRepo_iconCarrier">
                        <path
                            d="M10 7L15 12L10 17"
                            stroke="#808080"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        ></path>
                    </g>
                </svg>
                <StyleHeading as="a">{breadcrumbArticleListLabel??title}</StyleHeading>
            </Box>

            <ContentBox>
                <StyledBox>
                    <Heading
                        as="h2"
                        size="md"
                        sx={{margin: '10px', fontSize: '16px', marginLeft: '0px'}}
                    >
                        {props.mainListTitle}
                    </Heading>
                    <Heading as="h3" sx={{marginTop: '10px', fontSize: '14px', fontWeight: '100'}}>
                        {props.subListTitle}
                    </Heading>
                </StyledBox>

                {loading ? (
                    <Spinner size="xl" />
                ) : (
                    <>
                        {stories.map((story, i) => (
                            <Box
                                style={{margin: '15px 5px', display: 'flex', flexWrap: 'wrap'}}
                                key={i}
                            >
                                <StyledFormLabel>
                                    {story?.created
                                        ? locale === 'ja-JP'
                                            ? new Date(story.created)
                                                  .toISOString()
                                                  .split('T')[0]
                                                  .replace(/-/g, '/')
                                            : formatDate(new Date(story.created), {
                                                  year: 'numeric',
                                                  day: 'numeric',
                                                  month: 'short'
                                              })
                                        : ''}
                                </StyledFormLabel>
                                {story?.categories && (
                                    <StyledFormLabelAuthor>
                                        {story?.categories?.name || 'No category available'}
                                    </StyledFormLabelAuthor>
                                )}
                                <StyledLink  href={`${languagePath}${props.detailLink}?article=${story?.slug}`}>
                                    {story?.title || "No Title"}
                                </StyledLink>
                            </Box>
                        ))}

                        <HStack spacing={4} justify="center" fontWeight="100" my={4}>
                            {totalStories == 0 ? (
                                <>
                                    <p>{emptyDataMessage}</p>
                                </>
                            ) : (
                                <></>
                            )}

                            {totalPages > 1 ? (
                                <>
                                    <StyleButton
                                        border="none"
                                        size="xs"
                                        onClick={handlePrevPage}
                                        isDisabled={startIndex === 0}
                                    >
                                        &lt;
                                    </StyleButton>
                                    <Box>
                                        {Math.ceil(startIndex / storiesPerPage) + 1} / {totalPages}
                                    </Box>
                                    <StyleButton
                                        border="none"
                                        size="xs"
                                        onClick={handleNextPage}
                                        isDisabled={startIndex + storiesPerPage >= totalStories}
                                    >
                                        &gt;
                                    </StyleButton>
                                </>
                            ) : (
                                <></>
                            )}
                        </HStack>
                    </>
                )}
            </ContentBox>
        </Box>
    )
};

StoriesListing.propTypes = {
    main: PropTypes.object,
    title: PropTypes.string,
    description: PropTypes.string,
    keywords: PropTypes.array
};

export default StoriesListing;
