import React, {useState, useEffect} from 'react'
import {chakra, Box, Flex, Heading, Image, Text} from '@chakra-ui/react'
import Seo from '@salesforce/retail-react-app/app/components/seo'
import PropTypes from 'prop-types'
import {useSearchParams} from '@salesforce/retail-react-app/app/hooks'
import {getStories} from '../../../utils/magnoliaUtils'
import {useIntl} from 'react-intl'
import useMultiSite from '@salesforce/retail-react-app/app/hooks/use-multi-site'
import PageNotFound from '@salesforce/retail-react-app/app/pages/page-not-found'

const FlexBox = chakra(Flex, {
    baseStyle: {
        justifyContent: 'center'
    }
})
const StyleBreadcrumbHeading = chakra(Heading, {
    baseStyle: {
        fontSize: '14px',
        padding: '12px 0',
        fontWeight: '100',
        cursor: 'pointer',
        _hover: {
            textDecoration: 'underline'
        }
    }
})

const StyleHeading = chakra(Heading, {
    baseStyle: {
        fontSize: '14px',
        padding: '12px 0',
        fontWeight: '100'
    }
})
const StyleContainer = {
    maxWidth: '800px',
    margin: '0 auto',
    width: '100%'
}
const RichText = chakra(Text, {
    baseStyle: {
        '& a': {
            _hover: {
                textDecoration: 'underline'
            }
        },
        margin: '12px auto',
        maxWidth: '800px',
        width: '100%',
        fontWeight: '100',
        '& table[border="1"]': {
            margin: '24px 0',
            maxWidth: '100%',
            borderWidth: '1px',
            '& td': {
                padding: '6px 12px',
                borderWidth: '1px'
            }
        },
        '@media (max-width: 600px)': {
            '& table[border="1"]': {
                width: '100% !important'
            }
        }
    }
})
const StyledBox = chakra(Box, {
    baseStyle: {
        width: '100%',
        padding: '12px 0',
        maxWidth: '800px',
        fontWeight: '100',
        '& iframe': {
            maxWidth: '100%',
            width: '100%'
        }
    }
})


const StoryDetail = (props) => {
    const {
        main,
        title,
        description,
        keywords,
        story,
        breadcrumbHomeLabel,
        breadcrumbHomeLink,
        breadcrumbArticleListLabel,
        breadcrumbArticleListLink
    } = props
    const params = useSearchParams()
    const {article} = params[0]
    const [articleId, setArticleId] = useState(article)
    const [articleSlug, setArticleSlug] = useState(article)
    const [storyData, setStoryData] = useState()
    const [isVideoPlaying, setIsVideoPlaying] = useState(false)
    const intl = useIntl()
    const {formatDate} = intl
    const {site, buildUrl} = useMultiSite()
    const {l10n} = site
    const [locale, setLocale] = useState(intl.locale)
    const [languagePath, setLanguagePath] = useState('')
    const [isNotFound, setIsNotFound] = useState(false)
    useEffect(() => {
        if (locale !== l10n?.defaultLocale) {
            setLanguagePath(`/${locale.replace('-', '_')}`)
        }
    }, [locale])

    useEffect(() => {
        if (!article && story && story?.slug) {
            setArticleSlug(story?.slug)
        }
    }, [story?.slug])
    useEffect(() => {
        const getStoryData = async () => {
            const resData = await getStories({
                match: {
                    params: {
                        slug: articleSlug
                    }
                }
            })
            if (resData?.story) {
                setStoryData(resData.story)
            } else {
                setIsNotFound(true)
            }

        }
        if (!storyData || storyData?.slug !== articleId) {
            getStoryData()
        }
    }, [articleSlug])
    useEffect(() => {
        if (storyData) {
            if (storyData?.slug !== articleSlug) {
                setArticleId(story?.slug)
            }
        }
    }, [story])

    return (
        !isNotFound ?
            <Box data-testid="home-page" layerStyle="page">
                <Seo title={title} description={description}>
                    <meta name="keywords" content={keywords} />
                </Seo>

                <Flex sx={{alignItems: 'center'}}>
                    <StyleBreadcrumbHeading as="a" href={`${languagePath}${breadcrumbHomeLink}`}
                    >
                        {breadcrumbHomeLabel}
                    </StyleBreadcrumbHeading>
                    <svg
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16px"
                        height="16px"
                    >
                        <g id="SVGRepo_iconCarrier">
                            <path
                                d="M10 7L15 12L10 17"
                                stroke="#808080"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            ></path>
                        </g>
                    </svg>
                    <StyleBreadcrumbHeading as="a" href={`${languagePath}${breadcrumbArticleListLink}`}>

                        {breadcrumbArticleListLabel}
                    </StyleBreadcrumbHeading>
                    <svg
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16px"
                        height="16px"
                    >
                        <g id="SVGRepo_iconCarrier">
                            <path
                                d="M10 7L15 12L10 17"
                                stroke="#808080"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            ></path>
                        </g>
                    </svg>
                    <StyleBreadcrumbHeading as="h2">
                        {storyData?.categories?.name}
                    </StyleBreadcrumbHeading>
                </Flex>

                <FlexBox sx={StyleContainer}>
                    <StyleHeading as="h2">{storyData?.categories?.name}</StyleHeading>
                </FlexBox>

                <FlexBox sx={StyleContainer}>
                    <Heading as="h2" sx={{fontSize: '24px'}}>
                        {storyData?.title}
                    </Heading>
                </FlexBox>

                <FlexBox>
                    <StyleHeading as="h2" sx={{margin: '0'}}>
                        {storyData?.created
                            ? locale === 'ja-JP'
                                ? new Date(storyData.created).toISOString().split('T')[0].replace(/-/g, '/')
                                : formatDate(new Date(storyData.created), {
                                    year: 'numeric',
                                    day: 'numeric',
                                    month: 'short'
                                })
                            : ''}
                    </StyleHeading>
                </FlexBox>

                <FlexBox sx={StyleContainer}>
                    <Box style={{width: '100%'}}>
                        {(() => {
                            const {
                                visualType,
                                imagesource,
                                embedsource,
                                videosource,
                                videoimage,
                                embedimage
                            } = storyData || {}

                            switch (visualType) {
                                case 'image':
                                    return imagesource ? (
                                        <img
                                            src={imagesource}
                                            alt={storyData?.imagealtText || storyData?.title}
                                        />
                                    ) : (
                                        <></>
                                    )

                                case 'embed':
                                    return embedsource ? (
                                        <>
                                            {embedimage && !isVideoPlaying && (
                                                <img
                                                    onClick={() => setIsVideoPlaying(true)}
                                                    src={embedimage}
                                                    alt="Video Thumbnail"
                                                    style={{
                                                        marginBottom: '12px',
                                                        maxWidth: '100%',
                                                        borderRadius: '8px'
                                                    }}
                                                />
                                            )}
                                            {(embedimage && isVideoPlaying) || !embedimage ? (
                                                embedsource ? (
                                                    <StyledBox
                                                        dangerouslySetInnerHTML={{__html: embedsource}}
                                                    />
                                                ) : (
                                                    <></>
                                                )
                                            ) : (
                                                <></>
                                            )}
                                        </>
                                    ) : (
                                        <></>
                                    )

                                case 'video':
                                    return (
                                        <>
                                            {videoimage && !isVideoPlaying && (
                                                <img
                                                    onClick={() => setIsVideoPlaying(true)}
                                                    src={videoimage}
                                                    alt="Video Thumbnail"
                                                    style={{
                                                        marginBottom: '12px',
                                                        maxWidth: '100%',
                                                        borderRadius: '8px'
                                                    }}
                                                />
                                            )}
                                            {(videoimage && isVideoPlaying) || !videoimage ? (
                                                videosource ? (
                                                    <video
                                                        controls
                                                        style={{width: '100%', maxWidth: '800px'}}
                                                        autoPlay={isVideoPlaying}
                                                    >
                                                        <source src={videosource} type="video/mp4" />
                                                        <source src="movie.ogg" type="video/ogg" />
                                                        Your browser does not support the video tag.
                                                    </video>
                                                ) : (
                                                    <></>
                                                )
                                            ) : (
                                                <></>
                                            )}
                                        </>
                                    )

                                default:
                                    return <></>
                            }
                        })()}
                    </Box>
                </FlexBox>

                <FlexBox>{storyData?.lead ? <StyledBox>{storyData?.lead}</StyledBox> : <></>}</FlexBox>

                {storyData?.blocks?.['@nodes'].map((nodeKey) => {
                    const blockData = storyData?.blocks?.[nodeKey]
                    const type = blockData?.['mgnl:type']
                    switch (type) {
                        case 'image':
                            return (
                                <Image
                                    style={{margin: '0 auto', maxWidth: '800px', width: '100%'}}
                                    src={blockData?.image}
                                    alt={blockData?.imageAltText || 'alt of img'}
                                />
                            )

                        case 'richText':
                            let richText = blockData?.lightRichTextBox
                            return <RichText dangerouslySetInnerHTML={{__html: richText}} />
                        default:
                            break
                    }
                })}
            </Box>
            :
            <PageNotFound />

    )
}
StoryDetail.propTypes = {
    main: PropTypes.object,
    title: PropTypes.string,
    description: PropTypes.string,
    keywords: PropTypes.array
}
export default StoryDetail
