import React from 'react'
import PropTypes from 'prop-types'
import {chakra, AspectRatio, Heading, Image, Img} from '@chakra-ui/react'
import {isServer} from '@salesforce/retail-react-app/app/utils/utils'

const StyledContainer = chakra('div', {
    baseStyle: {
        width: ['100%', '50%'],
        display: 'inline-block',
        verticalAlign: 'top',
        padding: '1rem'
    }
})

const StyledHeading = chakra(Heading, {
    baseStyle: {
        fontSize: ['2rem', '3rem'],
        marginBottom: '0.4rem'
    }
})

const StyledRichText = chakra('div', {
    baseStyle: {
        fontFamily: 'Inter',
        fontWeight: '500',
        fontSize: ['0.8rem', '1rem'],
        marginTop: '1rem',
        ul: {
            listStylePosition: 'inside'
        }
    }
})

function TextImage({title, image, text}) {
    const src = image?.['@link']
    const alt = image?.metadata?.caption

    return (
        <StyledContainer>
            {title && <StyledHeading as="h2">{title}</StyledHeading>}
            {src && (
                <AspectRatio ratio={21 / 9} width="full">
                    {isServer ? (
                        <Image alt={alt} src={src} ignoreFallback={true} />
                    ) : (
                        <Img alt={alt} src={src} />
                    )}
                </AspectRatio>
            )}
            {text && <StyledRichText dangerouslySetInnerHTML={{__html: text}}></StyledRichText>}
        </StyledContainer>
    )
}

TextImage.propTypes = {
    title: PropTypes.string,
    image: PropTypes.object,
    text: PropTypes.string
}

export default TextImage
